import { truncateText } from "utils/truncateText";
import { TrajectoryActiveType } from "shared/types";
import { ReactComponent as RoundTimline } from "../../shared/ui/icon/RoundTimline.svg";
import { ReactComponent as RoundTimlineNS } from "../../shared/ui/icon/RoundTimlineNS.svg";

interface Props {
    active: TrajectoryActiveType | undefined;
    setOpenThreats: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenMaterials: React.Dispatch<React.SetStateAction<boolean>>;
    activeListLenght: number;
    index: number;
    selectedActiveId: number;
    setSelectedActiveId: (number) => void;
}

export const ActiveCard: React.FC<Props> = ({
    active,
    setOpenThreats,
    setOpenMaterials,
    index,
    activeListLenght,
    selectedActiveId,
    setSelectedActiveId,
}) => {
    const activeStat = (
        (active?.completed_threats_count / active?.threats_count) * 100 || 0
    ).toFixed(0);
    const activePos = index + 1;

    function openActive() {
        if (active.threats_count > 0 && active.content_count > 0)
            setSelectedActiveId(active.id);
        setOpenThreats(true);
    }

    // unused
    function closeActive() {
        setSelectedActiveId(active.id); // wtf
        setOpenThreats(false);
        setOpenMaterials(false);
    }

    return (
        <div
            className="relative flex min-h-[90px] w-[455px] cursor-pointer gap-x-[30px]"
            onClick={openActive}
        >
            <div
                className={`relative ${
                    activeListLenght === activePos
                        ? " "
                        : "after:absolute after:bottom-0 after:start-3.5 after:top-7 after:w-px after:-translate-x-[0.5px] after:bg-[#F3A773] last:after:hidden"
                } `}
            >
                {active.threats_count > 0 && active.content_count > 0 ? (
                    <div className="relative z-10 flex size-7 items-center justify-center">
                        {selectedActiveId === active.id ? (
                            <RoundTimlineNS />
                        ) : (
                            <RoundTimline />
                        )}
                    </div>
                ) : (
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-[-1px]"
                    >
                        <path
                            opacity="0.5"
                            d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM3 15C3 21.6274 8.37258 27 15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15Z"
                            fill="#ADADAD"
                        />
                    </svg>
                )}
            </div>

            <div className="grow pb-[60px] pt-0.5 ">
                <div className="flex w-full justify-between">
                    <div className="flex gap-x-[20px] ">
                        {active.threats_count > 0 &&
                        active.content_count > 0 ? (
                            <p className="w-[70px] text-start text-[24px] font-bold leading-[24px] text-orange">
                                {activeStat} %
                            </p>
                        ) : (
                            <svg
                                width="30"
                                height="36"
                                viewBox="0 0 30 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mt-[-9px]"
                            >
                                <path
                                    d="M14.6924 28.9218C14.6924 31.5006 14.6924 32.7901 15.5057 33.5912C16.3189 34.3923 17.6278 34.3923 20.2456 34.3923C22.8634 34.3923 24.1723 34.3923 24.9856 33.5912C25.7988 32.7901 25.7988 31.5006 25.7988 28.9218C25.7988 26.3429 25.7988 25.0535 24.9856 24.2523C24.1723 23.4512 22.8634 23.4512 20.2456 23.4512C17.6278 23.4512 16.3189 23.4512 15.5057 24.2523C14.965 24.785 14.7838 25.5336 14.723 26.7335"
                                    stroke="#ADADAD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12.2236 28.922C12.2236 26.3431 12.2236 25.0537 11.4103 24.2526C10.5971 23.4514 9.28818 23.4514 6.67038 23.4514C4.05258 23.4514 2.74368 23.4514 1.93043 24.2526C1.11719 25.0537 1.11719 26.3431 1.11719 28.922C1.11719 31.5008 1.11719 32.7903 1.93043 33.5914C2.74368 34.3926 4.05258 34.3926 6.67038 34.3926C9.28818 34.3926 10.5971 34.3926 11.4103 33.5914C11.9511 33.0587 12.1323 32.3102 12.193 31.1102"
                                    stroke="#ADADAD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12.2236 16.1571C12.2236 18.736 12.2236 20.0254 11.4103 20.8266C10.5971 21.6277 9.28818 21.6277 6.67038 21.6277C4.05258 21.6277 2.74368 21.6277 1.93043 20.8266C1.11719 20.0254 1.11719 18.736 1.11719 16.1571C1.11719 13.5783 1.11719 12.2888 1.93043 11.4877C2.74368 10.6865 4.05258 10.6865 6.67038 10.6865C9.28818 10.6865 10.5971 10.6865 11.4103 11.4877C11.9511 12.0204 12.1323 12.7689 12.193 13.9689"
                                    stroke="#ADADAD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M17.0149 13.5453C15.9198 11.2029 15.3723 10.0317 15.7708 8.96885C16.1693 7.90604 17.3582 7.36667 19.7359 6.28792C22.1137 5.20917 23.3026 4.6698 24.3814 5.06236C25.4603 5.45493 26.0078 6.62613 27.1029 8.96853C28.1979 11.3109 28.7454 12.4821 28.3469 13.5449C27.9484 14.6077 26.7595 15.1471 24.3818 16.2259C22.004 17.3046 20.8151 17.844 19.7363 17.4514C19.0189 17.1904 18.5365 16.5851 17.9718 15.5202"
                                    stroke="#ADADAD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </svg>
                        )}
                        <div className="flex flex-col gap-y-[15px]">
                            {active.threats_count > 0 &&
                            active.content_count > 0 ? (
                                <div className="flex h-[20px] min-w-[80px] max-w-[80px] items-center justify-center rounded bg-[#369F481A]">
                                    <p className="text-[12px] font-normal leading-[14.4px] text-[#369F48]">
                                        {active.non_empty_threats_count | 0}{" "}
                                        навыков
                                    </p>
                                </div>
                            ) : (
                                <div className="ml-[38px] flex h-[20px] min-w-[95px] max-w-[95px] items-center justify-center rounded bg-[#26435433]">
                                    <p className="text-[12px] font-normal leading-[14.4px] text-[#264354]">
                                        Формируется
                                    </p>
                                </div>
                            )}
                            {active.threats_count > 0 &&
                            active.content_count > 0 ? (
                                <p className="flex w-[220px] break-words text-[16px] font-medium leading-[19.2px]">
                                    {truncateText(active?.active?.name, 5)}
                                </p>
                            ) : (
                                <p className="ml-[38px] flex w-[220px] break-words text-[16px] font-medium leading-[19.2px] text-[#ADADAD]">
                                    {truncateText(active?.active?.name, 5)}
                                </p>
                            )}
                        </div>
                    </div>

                    {active.threats_count > 0 && active.content_count > 0 && (
                        <div className="flex flex-col items-end justify-end gap-y-[15px]">
                            <div className="flex gap-x-[10px] text-[12px] font-medium leading-[14.4px] text-[#ADADAD]">
                                Открыть &#8594;
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

import { createEvent, restore } from "effector";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Base64 } from "js-base64";
import * as http from "shared/http";
import useSWR from "swr";
import * as types from "shared/types";
import mem from "mem";
import { number } from "echarts";
import { any } from "io-ts";
import Authorization from "pages/login";

export let access_token;
export let refresh_token;
export type TokenState = "invalid" | "valid" | "loading" | "is_admin";
export const changeTokenState = createEvent<TokenState>();
export const $tokenState = restore(changeTokenState, "loading");

export const instance = axios.create({
    //baseURL: "/api/v1",
});

export function assignLocationAsync(url: string) {
    window.location.assign(url);
    return new Promise<void>((resolve) => setTimeout(resolve, 100000));
}

function extractResponseData<T>(response: AxiosResponse<T>) {
    if (response?.data) return response.data;
    throw new Error("Empty response");
}

function matchToken(token: string) {
    return /^(?:[\w-]*\.){2}[\w-]*$/.test(token);
}

function matchTokens(access: string, refresh: string) {
    return matchToken(access) && matchToken(refresh);
}

function parseToken(token: string): types.TokenPayload | null {
    if (!matchToken(token)) return null;

    const payload: string = token.split(".")[1];
    return JSON.parse(Base64.decode(payload));
}

export async function saveTokens(access: string, refresh: string) {
    access_token = access;
    refresh_token = refresh;

    localStorage.setItem("access_token", access);
    localStorage.setItem("refresh_token", refresh);

    instance.defaults.headers.common.Authorization = `Bearer ${access}`;
    if (parseToken(access).is_admin) {
        const force_user = localStorage.getItem("force_user");
        if (force_user === "true") {
            changeTokenState("valid");
        } else if (force_user === "false") {
            assignLocationAsync("/admin");
        } else {
            // пользователь еще не выбрал часть сайта
            // дефолт значение на случай если выбора не будет
            localStorage.setItem("force_user", "false");

            const response = await axios.get<{ count: number; results: any[] }>(
                "/api/v1/user/content",
                {
                    headers: { Authorization: `Bearer ${access}` },
                }
            );
            if (response.data.count > 0) {
                changeTokenState("is_admin");
            } else {
                assignLocationAsync("/admin");
            }
        }
    } else {
        changeTokenState("valid");
    }
}

async function verifyAccessToken(access_token: string): Promise<boolean> {
    if (!matchToken(access_token)) return false;
    try {
        await axios.post<void>("/api/v1/auth/jwt/verify", {
            token: access_token,
        });

        return true;
    } catch (error) {
        if (error instanceof AxiosError && error?.response?.status === 401)
            return false;

        throw error;
    }
}

async function refreshAccessToken(refresh_token: string): Promise<string> {
    const response = await axios.post<{ access: string }>(
        "/api/v1/auth/jwt/refresh",
        { refresh: refresh_token }
    );
    return extractResponseData(response).access;
}

const memoizedRefreshAccessToken = mem(refreshAccessToken, { maxAge: 100000 });
const memoizedSaveTokens = mem(saveTokens, { maxAge: 100000 });

export function request<T = void>(options: AxiosRequestConfig): Promise<T> {
    return instance.request<T>(options).then(extractResponseData);
}

async function retryRequest(config: AxiosRequestConfig) {
    try {
        const new_access = await memoizedRefreshAccessToken(refresh_token);
        await memoizedSaveTokens(new_access, refresh_token);
        return axios({
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${new_access}`,
            },
        });
    } catch (error) {
        const error_code = error?.response?.status;
        if (error_code === 401) {
            localStorage.clear();
            await assignLocationAsync("/login");
        } else {
            if (!window.location.pathname.startsWith("/error/"))
                await assignLocationAsync(`/error/${error_code}`);
        }
    }
}

async function errorInterceptor(error) {
    const ReservedErrorCodes = [404, 477];
    const config = error?.config;
    const error_code = error?.response?.status || 500;
    // console.log("interceptors.response error:", JSON.stringify(error));
    // console.log(
    //     "interceptors",
    //     JSON.stringify(error.response),
    //     JSON.stringify(error.response.data),
    //     error.message
    // );

    if (error_code === 401 && config) {
        return await retryRequest(config);
    } else if (ReservedErrorCodes.includes(error_code)) {
        return Promise.reject(error);
    } else {
        await assignLocationAsync(`/error/${error_code}`);
    }
}

instance.interceptors.response.use((res) => res, errorInterceptor);

export async function authWithCredentials(data: types.AuthData) {
    try {
        const response = await axios.post<types.AuthTokens>(
            "/api/v1/auth/jwt/create",
            data
        );

        const { access, refresh } = extractResponseData(response);
        if (!matchTokens(access, refresh)) throw new Error("Invalid response");

        await saveTokens(access, refresh);
    } catch (error) {
        console.log("Login error: ", error);
        const error_code = error?.response?.status || 500;

        if (error_code === 401) {
            throw new Error("Invalid credentials");
        } else {
            if (!window.location.pathname.startsWith("/error/"))
                await assignLocationAsync(`/error/${error_code}`);
        }
    }
}

async function authWithCache() {
    let access = localStorage.getItem("access_token") || "";
    let refresh = localStorage.getItem("refresh_token") || "";

    if (!matchTokens(access, refresh)) {
        changeTokenState("invalid");
        localStorage.clear();
        return;
    }

    try {
        const isAccessValid = await verifyAccessToken(access);
        if (!isAccessValid) access = await refreshAccessToken(refresh);

        await saveTokens(access, refresh);
    } catch (error) {
        console.log("Authorization error: ", error);
        const error_code = error?.response?.status || 500;

        if (error_code === 401) {
            localStorage.clear();
        } else {
            if (!window.location.pathname.startsWith("/error/"))
                await assignLocationAsync(`/error/${error_code}`);
        }
        changeTokenState("invalid");
    }
}

authWithCache();

globalThis["instance"] = instance;

export const ArrowComponent = () => {
    return (
        <div className="flex h-full min-h-[50px] grow">
            <div className="flex h-full flex-col items-center pb-[5px]">
                {/* <div className="flex h-[10px] w-[10px] items-center justify-center rounded-full border bg-[#F37D7380] text-[white]"></div> */}
                <div className="h-full w-[1px] border-[1px] border-dashed border-[#566DA3] opacity-50"></div>
                <svg
                    className="mt-[-45px]"
                    width="8"
                    height="48"
                    viewBox="0 0 8 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.64645 47.3536C3.84171 47.5488 4.15829 47.5488 4.35356 47.3536L7.53554 44.1716C7.7308 43.9763 7.7308 43.6597 7.53554 43.4645C7.34027 43.2692 7.02369 43.2692 6.82843 43.4645L4 46.2929L1.17157 43.4645C0.976313 43.2692 0.65973 43.2692 0.464468 43.4645C0.269206 43.6597 0.269206 43.9763 0.464468 44.1716L3.64645 47.3536ZM3.5 2.18557e-08L3.5"
                        fill="#566DA3"
                    />
                </svg>
            </div>
        </div>
    );
};

export const ArrowDependence = () => {
    return (
        <div className="flex h-full min-h-[50px] grow">
            <div className="flex h-full flex-col items-center pb-[5px]">
                {/* <div className="flex h-[10px] w-[10px] items-center justify-center rounded-full border bg-[#F37D7380] text-[white]"></div> */}
                <div className="h-full w-[1px] border-[1px] border-dashed border-[#F37D73] opacity-50"></div>
                {/* <svg
                    className="mt-[-45px]"
                    width="8"
                    height="48"
                    viewBox="0 0 8 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.64645 47.3536C3.84171 47.5488 4.15829 47.5488 4.35356 47.3536L7.53554 44.1716C7.7308 43.9763 7.7308 43.6597 7.53554 43.4645C7.34027 43.2692 7.02369 43.2692 6.82843 43.4645L4 46.2929L1.17157 43.4645C0.976313 43.2692 0.65973 43.2692 0.464468 43.4645C0.269206 43.6597 0.269206 43.9763 0.464468 44.1716L3.64645 47.3536ZM3.5 2.18557e-08L3.5"
                        fill="#566DA3"
                    />
                </svg> */}
                <svg
                    width="8"
                    height="102"
                    viewBox="0 0 8 102"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-[-95px]"
                >
                    <path
                        d="M3.64644 101.354C3.8417 101.549 4.15829 101.549 4.35355 101.354L7.53553 98.1716C7.73079 97.9763 7.73079 97.6597 7.53553 97.4645C7.34027 97.2692 7.02369 97.2692 6.82842 97.4645L4 100.293L1.17157 97.4645C0.976306 97.2692 0.659724 97.2692 0.464462 97.4645C0.2692 97.6597 0.2692 97.9763 0.464462 98.1716L3.64644 101.354ZM3.5 -2.18557e-08L3.5 2.525L4.5 "
                        fill="#F37D73"
                    />
                </svg>
            </div>
        </div>
    );
};

export const ArrowDependenceEnd = () => {
    return (
        <div className="flex h-full min-h-[50px] grow">
            <div className="flex h-full flex-col items-center pb-[5px]">
                {/* <div className="flex h-[10px] w-[10px] items-center justify-center rounded-full border bg-[#F37D7380] text-[white]"></div> */}
                <div className="h-full w-[1px] border-[1px] border-dashed border-[#F37D73] opacity-50"></div>
                <div className="flex h-[6px] w-[6px] rounded-full bg-[#F37D73]"></div>
            </div>
        </div>
    );
};

import * as types from "shared/types";
import * as http from "shared/http";
import { useQuery } from "@tanstack/react-query";
import {Paginated, ActiveThreatType} from "shared/types";

const actives_url = "/api/v1/user/actives_threats";

async function queryFn(filters) {
  const response = await http.request<Paginated<ActiveThreatType>>({
    method: "get",
    url: actives_url,
    params: filters,
  });

  return response.results;
}

export function useActiveThreats(filters={}, enabled=true) {
  return useQuery({
    queryKey: [actives_url, filters],
    queryFn: () => queryFn(filters),
    enabled,
  });
}
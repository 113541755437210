import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Exit } from "shared/ui/icon/Exit.svg";
import { ReactComponent as MenuIcon } from "shared/ui/icon/MenuIcon.svg";
import { ReactComponent as Notifications } from "shared/ui/icon/Notifications.svg";
import { ReactComponent as Settings2 } from "shared/ui/icon/Settings2.svg";
import { ReactComponent as CloseB } from "shared/ui/icon/CloseB.svg";
import { ReactComponent as MenuSelect } from "shared/ui/icon/MenuSelect.svg";
import studentPic from "shared/ui/pic/studentPic.png";
import { NavbarMenu } from "../NavbarMenu/NavbarMenu";
import { NavbarSettingsProfile } from "../NavbarSettingsProfile/NavbarSettingsProfile";
import { NavbarNotification } from "components/NavbarNotification/NavbarNotification";
import useUser from "shared/api/useUser";
import "./Header.css";
import { ExitAdminForm } from "components/Forms/ExitAdminForm";
import useMaterialsList from "shared/api/useUserContent";

export const Header: React.FC = () => {
    const { user } = useUser();
    const [openMenu, setOpenMenu] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openExitPopup, setOpenExitPopup] = useState(false);

    const { materialsList } = useMaterialsList();

    const toggleMenu = () => {
        setOpenMenu((prevState) => !prevState);
        if (!openMenu) {
            setOpenProfile(false);
            setOpenNotifications(false);
        }
    };

    const toggleProfile = () => {
        setOpenProfile((prevState) => !prevState);
        if (!openProfile) {
            setOpenNotifications(false);
            setOpenMenu(false);
        }
    };

    const toggleNotifications = () => {
        setOpenNotifications((prevState) => !prevState);
        if (!openNotifications) {
            setOpenProfile(false);
            setOpenMenu(false);
        }
    };

    function exitStrategy() {
        if (user.is_staff === true) {
            setOpenExitPopup(true);
        } else {
            localStorage.clear();
            window.location.assign("/login");
        }
    }

    return (
        <>
            <header className="header">
                <div className="mx-auto flex h-[75px] w-full max-w-[1415px] items-center justify-between max-[834px]:h-[45px]">
                    <div className="flex items-center gap-[32px]">
                        <div className="flex max-[834px]:hidden">
                            <div
                                onClick={toggleMenu}
                                className="h-[21px] w-[21px] cursor-pointer hover:opacity-70"
                            >
                                {openMenu ? (
                                    <CloseB className="cursor-pointer hover:opacity-70" />
                                ) : (
                                    <MenuSelect className="cursor-pointer hover:opacity-70" />
                                )}
                            </div>
                        </div>
                        <div>
                            <Link to="/">
                                <MenuIcon className="cursor-pointer hover:opacity-70" />
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="mr-[35px] max-[834px]:hidden">
                            <div
                                onClick={toggleNotifications}
                                className="h-[21px] w-[21px] cursor-pointer hover:opacity-70"
                            >
                                {openNotifications ? (
                                    <CloseB className="cursor-pointer hover:opacity-70" />
                                ) : (
                                    <Notifications className="cursor-pointer hover:opacity-70" />
                                )}
                            </div>
                        </div>
                        <div className="mr-[35px] flex min-w-[25px] gap-[3px] max-[834px]:hidden">
                            <img
                                src={studentPic}
                                alt="manIcon"
                                className="mr-[30px] h-[45px] w-[45px] rounded-full border bg-gray-100"
                            />
                            <div className="flex flex-col justify-center">
                                {user?.first_name === null &&
                                user?.surname === null ? (
                                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354CC]">
                                        {user?.email}
                                    </p>
                                ) : (
                                    <>
                                        <p className="text-[10px] font-semibold leading-[12px] text-[#26435466]">
                                            {user?.surname}
                                        </p>
                                        <p className="text-[10px] font-semibold leading-[12px] text-[#26435466]">
                                            {user?.first_name}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div
                            className="hidden cursor-pointer max-[834px]:flex"
                            onClick={toggleProfile}
                        >
                            <div className="flex min-w-[25px] flex-col justify-center gap-[3px] max-[834px]:mr-[10px]">
                                {user?.first_name === null &&
                                user?.surname === null ? (
                                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354CC]">
                                        {user?.email}
                                    </p>
                                ) : (
                                    <p className="text-[10px] font-semibold leading-[12px] text-[#26435466]">
                                        {user?.surname} {user?.first_name}
                                    </p>
                                )}
                            </div>
                            <img
                                src={studentPic}
                                alt="manIcon"
                                className="h-[37px] w-[37px] rounded-full border bg-gray-100"
                            />
                            {/* Иконка пользователя */}
                        </div>
                        <div className="mr-[18px] max-[834px]:hidden">
                            <div
                                onClick={toggleProfile}
                                className="h-[21px] w-[21px] cursor-pointer hover:opacity-70"
                            >
                                {openProfile ? (
                                    <CloseB className="cursor-pointer hover:opacity-70" />
                                ) : (
                                    <Settings2 className="cursor-pointer hover:opacity-70" />
                                )}
                            </div>
                        </div>
                        <div
                            className="max-[834px]:hidden"
                            onClick={exitStrategy}
                        >
                            <Exit className="cursor-pointer hover:opacity-70" />
                        </div>
                    </div>
                </div>
            </header>
            <NavbarMenu openMenu={openMenu} toggleMenu={toggleMenu} />
            <NavbarNotification
                openNotifications={openNotifications}
                toggleNotifications={toggleNotifications}
            />
            <NavbarSettingsProfile
                openProfile={openProfile}
                toggleProfile={toggleProfile}
            />
            {openExitPopup === true && (
                <ExitAdminForm setShowPopup={setOpenExitPopup} />
            )}
        </>
    );
};
